"use client";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dataService from "../../services/data.service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import VideoTipItem from "./item/videoTipItem";
import { VideoResponseItem } from "../../types/videoResponseItem";
import authenticationService from "../../services/authentication.service";
import { assetTypes } from "../../config/constants";

const Loader = () => (
  <div className="flex justify-center items-center mt-10">
    <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-pink-500"></div>
  </div>
);

const OverviewPage: React.FC = () => {
  // const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState(null);
  const [videosAndTips, setVideosAndTips] = useState<VideoResponseItem[]>([]);
  const [popupOpen, setPopupOpen] = useState(false);
  const [videosFetched, setVideosFetched] = useState(false);
  const [companyFetched, setCompanyFetched] = useState(false);
  const [userLanguage, setUserLanguage] = useState(null);
  const [softwareLanguage, setSoftwareLanguage] = useState(null);
  const [error, setError] = useState<string | null>(null);
  const [oasePlusEnabled, setOasePlusEnabled] = useState(false);
  const [oasePlusBookingLink, setOasePlusBookingLink] = useState(null);
  const location = useLocation();

  let officeHost: string = Office.context.host.toString().toLowerCase();

  useEffect(() => {
    setLanguages();
  }, []);

  useEffect(() => {
    const timeOutId = setTimeout(() => getVideosAndTips(), 1000);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  const getVideosAndTips = () => {
    dataService
      .getFilteredVideos(officeHost, searchValue)
      .then((results) => {
        const filteredVideos = results.filter((video) => video.Assets.length > 0);

        setVideosAndTips(filteredVideos);
        setVideosFetched(true);
      })
      .catch((ex) => {
        if (ex.message.inc) console.log(ex);
      });
  };

  const search = (event) => {
    setSearchValue(event.target.value);
  };

  const setLanguages = () => {
    const previousUserLanguage = localStorage.getItem("userLanguage");
    if (previousUserLanguage) {
      setUserLanguage(previousUserLanguage);
      setSoftwareLanguage(localStorage.getItem("softwareLanguage"));
      const previousAddinLanguage = previousUserLanguage.substring(0, 2).toLowerCase();
      i18n.changeLanguage(previousAddinLanguage);
    }
    getUserProfile();
  };

  const getUserProfile = async () => {
    try {
      const res = await authenticationService.getUserProfile();

      dataService
        .getCompanyById(res.data.data.relationships.company.data.id)
        .then((result) => {
          console.log(result);
          if (result) {
            setOasePlusEnabled(result.OasePlusEnabled);
            setOasePlusBookingLink(result.OasePlusBookingLink);
          }
        })
        .catch((ex) => {
          if (ex.message.inc) console.log(ex);
        });

      const userLang = res.data.included[0].attributes.locale;
      const softwareLang = res.data.included[0].attributes.video_locale;

      setUserLanguage(userLang);
      localStorage.setItem("userLanguage", userLang);

      setSoftwareLanguage(softwareLang);
      localStorage.setItem("softwareLanguage", softwareLang);

      const addinUserLanguage = userLang.substring(0, 2).toLowerCase();
      i18n.changeLanguage(addinUserLanguage);
    } catch (err) {
      setError(err.toString());
    }
  };

  const openPopup = (assets) => {
    const popupSize = parseInt(localStorage.getItem("popupSize")) || 50;
    const softwareLanguage = localStorage.getItem("softwareLanguage");
    const userLanguage = localStorage.getItem("userLanguage");

    const videoAssets = assets.filter((x) => x.Type === assetTypes.VIDEO);
    const subtitleAssets = assets.filter((x) => x.Type === assetTypes.SUBTITLE);

    let filteredVideoAssets = videoAssets.filter(
      (x) => x.LanguageSpoken === userLanguage && x.LanguageSoftware === softwareLanguage
    );

    if (filteredVideoAssets.length === 0) {
      filteredVideoAssets = videoAssets.filter((x) => x.Locale === userLanguage);
      if (filteredVideoAssets.length === 0) {
        filteredVideoAssets = videoAssets;
      }
    }

    const videoAsset = filteredVideoAssets[0];
    let subtitlePath = "";
    let subtitleLanguage = "";

    const filteredSubtitleAssets = subtitleAssets.filter((x) => x.ParentId === videoAsset.Id);
    if (filteredSubtitleAssets.length > 0) {
      const localeFilteredSubtitles = filteredSubtitleAssets.filter((x) => x.Locale === userLanguage);
      const selectedSubtitle =
        localeFilteredSubtitles.length > 0 ? localeFilteredSubtitles[0] : filteredSubtitleAssets[0];
      subtitlePath = selectedSubtitle.AzLink;
      subtitleLanguage = selectedSubtitle.Locale;
    }

    const popupOptions = {
      width: popupSize,
      height: Math.ceil(((720 / (1152 / ((screen.width / 100) * popupSize)) + 40) / screen.height) * 100), // dependent on video size (1152x720) & screen size
      displayInIframe: true,
    };

    const encodingId = videoAsset.EncodingId;
    const url = `${process.env.REACT_APP_ROOT_URL}?encodingId=${encodingId}&subpath=${subtitlePath}&sublang=${subtitleLanguage}#/popup`;

    Office.context.ui.displayDialogAsync(url, popupOptions, (result) => {
      console.log(result);
    });
  };

  const handleBookTrainer = () => {
    var url = "https://outlook.office365.com/book/OASE2@xylosnvonline.onmicrosoft.com/";
    if (oasePlusBookingLink) {
      url = oasePlusBookingLink;
    }

    window.open(url, "_blank");
  };

  return (
    <div className="container">
      <div className="absolute top-0 right-0 left-0 flex justify-between items-center">
        <div className="ml-2 mt-2">
          <Link to="/settings">
            <FontAwesomeIcon icon={faGear} />
          </Link>
        </div>
        <div className="mr-2 mt-2">
          {oasePlusEnabled && (
            <button
              className="bg-pink-600 text-white font-medium rounded-lg text-xs px-4 py-1.5 hover:bg-pink-700 focus:ring-4 focus:ring-pink-300"
              onClick={handleBookTrainer}
            >
              {t("BOOK A COACHING SESSION")}
            </button>
          )}
        </div>
      </div>

      <div>
        <input
          type="email"
          className={`w-full py-1.5 px-3 text-base leading-normal text-control-text bg-white rounded transition-border-shadow duration-150 ease-in-out form-control border border-border-default focus:border-border-focus focus:text-control-color focus:bg-control-bg focus:outline-none focus:shadow-control`}
          onChange={search}
          placeholder={t("SEARCH")}
        />
        {videosFetched ? (
          <div className="mt-6 mb-4">
            {videosAndTips.map((x) => {
              return <VideoTipItem key={x.Id} openPopup={() => openPopup(x.Assets)} item={x}></VideoTipItem>;
            })}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default OverviewPage;
